<template>
    <div class="multidimensionalBar" id="multidimensionalBar"></div>
</template>

<script>
export default {
    //import引入组件才能使用
    components: {},
    props: ['timeFrame', 'termId'],
    data() {
        return {

        };
    },
    // 计算属性
    computed: {},
    // 监听data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        multidimensionalBar_graphics() {
            this.$echarts.init(document.getElementById("multidimensionalBar")).dispose();
            let that = this;
            let myChart = this.$echarts.init(
                document.getElementById("multidimensionalBar")
            );
            let charts = {
                lineX: ['0-8时', '8-12时', '12-13时', '13-15时', '15-17时', '17-24时'],
                value: this.timeFrame,
            }
            if (this.termId == '20231' || this.termId == '20232') {
                charts.names = ['同步课堂', '每日一字', '硬笔体系课', '软笔体系课']
            } else {
                charts.names = ['同步课程', '简洁版', '硬笔体系课', '软笔体系课']
            }
            let color = [
                'rgba(41, 197, 224',
                'rgba(28, 122, 194',
                'rgba(214, 151, 91',
                'rgba(0, 246, 3',
            ]
            let lineY = []

            for (let i = 0; i < charts.names.length; i++) {
                let x = i
                if (x > color.length - 1) {
                    x = color.length - 1
                }
                let data = {
                    name: charts.names[i],
                    type: 'line',
                    //  stack: '总量',
                    color: color[x] + ')',
                    smooth: true,
                    areaStyle: {
                        normal: {
                            color: new this.$echarts.graphic.LinearGradient(
                                0,
                                0,
                                0,
                                1,
                                [
                                    {
                                        offset: 0,
                                        color: color[x] + ', 0.3)',
                                    },
                                    {
                                        offset: 0.8,
                                        color: color[x] + ', 0)',
                                    },
                                ],
                                false
                            ),
                            shadowColor: 'rgba(0, 0, 0, 0.1)',
                            shadowBlur: 10,
                        },
                    },
                    symbol: 'circle',
                    symbolSize: 5,
                    data: charts.value[x],
                    lineStyle: {
                        width: 1,
                    },
                }
                lineY.push(data)
            }
            myChart.setOption({
                tooltip: {
                    trigger: 'axis',
                    textStyle: {
                        fontSize: this.graphicRatio(12),
                    }
                },
                legend: {
                    data: charts.names,
                    textStyle: {
                        fontSize: this.graphicRatio(12),
                        fontWeight: '200',
                        color: '#fff',
                    },
                    type: 'scroll',
                    bottom: this.graphicRatio(3),
                    align: 'left',
                    itemWidth: this.graphicRatio(15),
                    itemHeight: this.graphicRatio(10),
                },
                grid: {
                    top: '8%',
                    left: '4%',
                    right: '6%',
                    bottom: '12%',
                    containLabel: true,
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: charts.lineX,
                    axisLine: {
                        lineStyle: {
                            color: '#2e76c4',
                        },
                    },
                    splitLine: {
                        lineStyle: {
                            color: 'rgb(183, 183, 183)',
                        },
                    },
                    axisLabel: {
                        textStyle: {
                            color: '#fff',
                            fontSize: this.graphicRatio(12),
                        },
                    },
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        formatter: '{value}',
                        textStyle: {
                            color: '#fff',
                            fontSize: this.graphicRatio(12),
                        },
                    },
                    splitLine: {
                        lineStyle: {
                            color: 'rgba(183, 183, 183,0.3)',
                            type: 'dashed',
                        },
                    },
                    axisLine: {
                        lineStyle: {
                            color: 'rgba(183, 183, 183,0.3)',
                        },
                    },
                },
                series: lineY,
            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
    },
    // 生命周期，创建完成时（可以访问当前this实例）
    created() {

    },
    // 生命周期：挂载完成时（可以访问DOM元素）
    mounted() {
        this.multidimensionalBar_graphics()
    },
}
</script>
<style scoped>
.multidimensionalBar {
    width: 100%;
    height: 100%;
}
</style>