<template>
    <div class="pictorialBar" id="pictorialBar"></div>
</template>

<script>
export default {
    //import引入组件才能使用
    components: {},
    props: ['pictorialName','pictorialValue'],
    data() {
        return {

        };
    },
    // 计算属性
    computed: {},
    // 监听data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        pictorialBar_graphics() {
            this.$echarts.init(document.getElementById("pictorialBar")).dispose();
            let that = this;
            let myChart = this.$echarts.init(
                document.getElementById("pictorialBar")
            );
            let data = this.pictorialValue;
            myChart.setOption({
                grid: {
                    // top: '3%',
                    bottom: '15%',
                    right: '25%',
                    left: '25%',
                },
                xAxis: {
                    data: this.pictorialName,
                    axisTick: { show: false },
                    axisLine: { show: false },
                    axisLabel: {
                        color: '#FFF',
                        fontSize: this.graphicRatio(11),
                    }
                },
                yAxis: {
                    splitLine: { show: false },
                    axisTick: { show: false },
                    axisLine: { show: false },
                    offset: this.graphicRatio(30),
                    axisLabel: { show: false },
                },
                color: ['#41C8D6'],
                series: [
                    {
                    name: 'hill',
                    type: 'pictorialBar',
                    barCategoryGap: '-170%',
                    symbolClip: true,
                    symbol: 'path://M0,10 L10,10 C5.5,10 5.5,5 5,0 C4.5,5 4.5,10 0,10 z',
                    emphasis: {
                        itemStyle: {
                            opacity: 1
                        }
                    },
                    itemStyle: {
                        color: function (params) {
                            if (params.dataIndex == 2) {
                                return {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [
                                        {
                                            offset: 0,
                                            color: 'rgba(77, 255, 148, 0)',
                                        },
                                        {
                                            offset: 1,
                                            color: 'rgba(77, 255, 148, 0.7)',
                                        },
                                    ],
                                    global: false, //  缺省为  false
                                }
                            } else if (params.dataIndex == 1) {
                                return {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [
                                        {
                                            offset: 0,
                                            color: 'rgba(255, 170, 49, 0)',
                                        },
                                        {
                                            offset: 1,
                                            color: 'rgba(255, 170, 49, 0.7)',
                                        },
                                    ],
                                    global: false, //  缺省为  false
                                }
                            } else {
                                return {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [
                                        {
                                            offset: 0,
                                            color: 'rgba(255, 84, 31, 0)',
                                        },
                                        {
                                            offset: 1,
                                            color: 'rgba(255, 84, 31, 0.7)',
                                        },
                                    ],
                                    global: false, //  缺省为  false
                                }
                            }
                        },
                    },
                    label: {
                        show: true,
                        position: 'top',
                        textStyle: {
                            color: '#fff',
                            fontSize: this.graphicRatio(11)
                        },
                        formatter: function (params) {
                            return params.value
                        },

                    },
                    data,
                    z: 10
                },
            ]
            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
    },
    // 生命周期，创建完成时（可以访问当前this实例）
    created() {

    },
    // 生命周期：挂载完成时（可以访问DOM元素）
    mounted() {
        this.pictorialBar_graphics()
    },
    beforeCreate() { },//生命周期：创建之前
    beforeMount() { },//生命周期：挂载之前
    beforeUpdate() { },//生命周期：更新之前
    updated() { },//生命周期：更新之后
    beforeDestroy() { },//生命周期：销毁之前
    destroyed() { },//生命周期：销毁完成
    activated() { },//如果页面有keep-alive缓存功能，这个函数会触发执行
}
</script>
<style scoped>
.pictorialBar {
    width: 100%;
    height: 100%;
}
</style>