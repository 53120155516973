<template>
    <div class="bothLine" id="bothLine"></div>
</template>

<script>
export default {
    //import引入组件才能使用
    components: {},
    props: ['bothLineName', 'bothLineValue'],
    data() {
        return {

        };
    },
    // 计算属性
    computed: {},
    // 方法集合
    methods: {
        bothLine_graphics(name, value) {
            this.$echarts.init(document.getElementById("bothLine")).dispose();
            let that = this;
            let myChart = this.$echarts.init(
                document.getElementById("bothLine")
            );
            myChart.setOption({
                grid: {
                    top: '20%',
                    left: '5%',
                    right: '8%',
                    bottom: '8%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    // data: name,
                    data: that.bothLineName,
                    axisLine: {
                        lineStyle: {
                            color: "#2e76c4"
                        }
                    },
                    axisTick: {

                    },
                    splitLine: {
                        show: false,

                    },
                    axisLabel: {
                        color: '#fff',
                        interval: 0,
                        fontSize:this.graphicRatio(12)
                    }
                },
                yAxis: [{
                    type: "value",
                    name: '单位：次',
                    axisLine: {
                        show: true, // 显示x轴线
                        lineStyle: {
                            color: "#2e76c4"
                        }
                    },
                    splitLine: {
                        show: false,

                    },
                    axisLabel: {
                        color: '#fff',
                        fontSize:this.graphicRatio(12)
                    },
                    nameTextStyle: {
                        color: '#fff',
                        // padding: [0, 0, 0, 15],
                        fontSize:this.graphicRatio(12)
                    },
                    axisTick: {
                        show: false
                    }
                }],
                series: [
                    {
                        name: '应用统计',
                        type: "line",
                        symbol: 'circle', //设定为实心点
                        showAllSymbol: true,
                        smooth: true,
                        label: {
                            show: true,
                            position: 'top',
                            color: '#fff',
                            // fontSize: 10,
                            fontSize:this.graphicRatio(12),
                            formatter: function (params) {
                                return params.xValue;
                            }
                        },
                        itemStyle: {
                            normal: {
                                color: '#fff',
                                borderColor: '#01e082', // 边框颜色
                                borderWidth: this.graphicRatio(1), // 边框宽度
                                lineStyle: { //线的颜色
                                    color: '#01e082',
                                    width: this.graphicRatio(2),
                                },
                                areaStyle: {
                                    color: {
                                        type: 'linear',
                                        x: 0,
                                        y: 0,
                                        x2: 0,
                                        y2: 1,
                                        colorStops: [
                                            {
                                                offset: 0,
                                                color: 'rgba(1, 224, 130, 0.6)'
                                            },
                                            {
                                                offset: 1,
                                                color: 'rgba(1, 224, 130, 0.1)'
                                            }
                                        ]
                                    }
                                },
                            }
                        },
                        // data: value
                        data: that.bothLineValue
                    },
                ]
            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
    },
    // 生命周期，创建完成时（可以访问当前this实例）
    created() {

    },
    // 生命周期：挂载完成时（可以访问DOM元素）
    mounted() {
        this.bothLine_graphics(this.bothLineName,this.bothLineValue)
    },
    beforeCreate() { },//生命周期：创建之前
    beforeMount() { },//生命周期：挂载之前
    beforeUpdate() { },//生命周期：更新之前
    updated() { },//生命周期：更新之后
    beforeDestroy() { },//生命周期：销毁之前
    destroyed() { },//生命周期：销毁完成
    activated() { },//如果页面有keep-alive缓存功能，这个函数会触发执行
}
</script>
<style scoped>
.bothLine {
    width: 100%;
    height: 100%;
}
</style>