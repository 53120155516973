<template>
    <div class="stackBar" id="stackBar"></div>
</template>

<script>
export default {
    //import引入组件才能使用
    components: {},
    props: ['lessontypeName', 'lessontypeValue', 'ApplicationMaxData','termId'],
    data() {
        return {

        };
    },
    // 计算属性
    computed: {},
    // 监听data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        stackBar_graphics() {
            console.log('termId',this.termId);
            
            this.$echarts.init(document.getElementById("stackBar")).dispose();
            let that = this;
            let obj = {
                ydata: this.lessontypeName,
                data: this.lessontypeValue
            };
            // .slice(0, 10)
            obj.ydata = obj.ydata.slice(0, 10)
            obj.data.data1 = obj.data.data1.slice(0, 10)
            obj.data.data2 = obj.data.data2.slice(0, 10)
            obj.data.data3 = obj.data.data3.slice(0, 10)
            obj.data.data4 = obj.data.data4.slice(0, 10)
            // obj.data.data5 = obj.data.data5.slice(0, 10)
            let salvProMax = this.ApplicationMaxData.slice(0, 10); // 背景按最大值
            let myChart = this.$echarts.init(
                document.getElementById("stackBar")
            );

            myChart.setOption({
                //鼠标滑过提示栏
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow',
                    },
                    textStyle: {
                        fontSize: this.graphicRatio(12),
                    },
                    formatter: (params) => {
                        let arr = [...params].slice(0, -1);
                        let str = '';
                        arr.forEach((item) => {
                            str += item.marker + item.seriesName + '：' + item.data + '<br />';
                        });
                        str = arr[0].name + '<br />' + str;
                        return str;
                    },
                },
                // dataZoom: [
                //     // 横向柱状图，y轴超过15条数据就会展示出来
                //     {
                //         type: 'inside', // 放大和缩小
                //         orient: 'vertical',
                //         show: obj.ydata.length > 15,
                //         start: 0,
                //         end: obj.ydata.length > 15 ? 30 : 100, // 控制初始化时展示的百分比数量
                //         height: 15,
                //         left: '5%',
                //         right: '5%',
                //         bottom: '2%',
                //         moveHandleSize: 0, //去掉进度条顶部的横向拉动进度条
                //     },
                // ],
                //图表选择栏
                legend: {
                    bottom: this.graphicRatio(3),
                    itemWidth: this.graphicRatio(14),
                    itemHeight: this.graphicRatio(14),
                    textStyle: {
                        color: '#fff',
                        fontSize: this.graphicRatio(12)
                    }
                },
                //图标离容器的距离
                grid: {
                    left: '5%',
                    right: '1%',
                    bottom: '10%',
                    top: '3%',
                    containLabel: true,
                },
                //x坐标轴
                xAxis: [
                    {
                        type: 'value',
                        axisTick: {
                            show: false,
                        },
                        boundaryGap: true,
                        axisLine: {
                            show: false,
                        },
                        axisLabel: {
                            show: false,
                        },
                        splitLine: {
                            show: false,
                        },
                    },
                ],

                //y坐标轴
                yAxis: [
                    {
                        type: 'category',
                        data: obj.ydata,
                        splitLine: {
                            show: false,
                        },
                        inverse: true,
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "#2e76c4",
                                width: this.graphicRatio(1),
                                type: 'solid',
                            },
                        },
                        axisLabel: {
                            textStyle: {
                                color: '#fff',
                                fontSize: this.graphicRatio(12),
                            },
                        },
                    },
                ],
                // 数据展示区域
                series: [
                    {
                        name: '同步课程',
                        type: 'bar',
                        color: '#4150d8',
                        stack: 'Ad',
                        barWidth: this.graphicRatio(16),
                        label: {
                            show: true,
                            color: '#fff',
                            fontSize: this.graphicRatio(12),
                        },
                        data: obj.data.data1,
                    },
                    {
                        name: this.termId =='20231' || this.termId =='20232' ? '每日一字' : '简洁版',
                        type: 'bar',
                        color: '#2ec181',
                        stack: 'Ad',
                        barWidth: this.graphicRatio(16),
                        label: {
                            show: true,
                            color: '#fff',
                            fontSize: this.graphicRatio(12),
                        },
                        data: obj.data.data2,
                    },
                    {
                        name: '硬笔体系课',
                        type: 'bar',
                        color: '#ed7c2f',
                        stack: 'Ad',
                        barWidth: this.graphicRatio(16),
                        label: {
                            show: true,
                            color: '#fff',
                            fontSize: this.graphicRatio(12),
                        },
                        data: obj.data.data3,
                    },
                    {
                        name: '软笔体系课',
                        type: 'bar',
                        color: '#f2a93b',
                        stack: 'Ad',
                        barWidth: this.graphicRatio(16),
                        label: {
                            show: true,
                            color: '#fff',
                            fontSize: this.graphicRatio(12),
                        },
                        data: obj.data.data4,
                    },
                    {
                        name: '',
                        color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [
                            {
                                offset: 0,
                                color: 'rgba(14, 40, 88,0)'
                            },
                            {
                                offset: 0.2,
                                color: 'rgba(14, 40, 88,0.8)'
                            },
                            {
                                offset: 0.9,
                                color: 'rgba(14, 40, 88,1)'
                            }
                        ]),
                        type: 'bar',
                        z: 1,
                        barWidth: this.graphicRatio(16),
                        barGap: '-100%',
                        data: salvProMax,
                    }
                ],
            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
    },
    // 生命周期，创建完成时（可以访问当前this实例）
    created() {

    },
    // 生命周期：挂载完成时（可以访问DOM元素）
    mounted() {
        this.stackBar_graphics()
    },
}
</script>
<style scoped>
.stackBar {
    width: 100%;
    height: 100%;
}
</style>