<template>
    <div class="pie" id="pie"></div>
</template>

<script>
export default {
    //import引入组件才能使用
    components: {},
    props: ['pieValue'],
    data() {
        return {

        };
    },
    // 计算属性
    computed: {},
    // 监听data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        pie_graphics() {
            this.$echarts.init(document.getElementById("pie")).dispose();
            let that = this;
            let myChart = this.$echarts.init(
                document.getElementById("pie")
            );
            let color = ['#e8543c', '#26baff', '#3dac62', '#fcc006'];
            myChart.setOption({
                color: color,
                legend: {
                    bottom: that.graphicRatio(3),
                    itemWidth: that.graphicRatio(12),
                    itemHeight: that.graphicRatio(12),
                    textStyle: {
                        color: '#fff',
                        fontSize: that.graphicRatio(12),
                    }
                },
                series: [
                    {
                        name: '',
                        type: 'pie',
                        radius: ['32%', '50%'],
                        center: ['50%', '45%'],
                        avoidLabelOverlap: true,
                        label: {
                            color: '#fff',
                            alignTo: 'labelLine',
                            formatter: '{name|{b}}：{num|{c}}次',
                            minMargin: 5,
                            edgeDistance: 10,
                            lineHeight: 9,
                            fontSize:that.graphicRatio(12),
                            rich: {
                                num: {
                                    fontSize:that.graphicRatio(12),
                                    color: '#0be5ff'
                                },
                                zb: {
                                    fontSize:that.graphicRatio(12),
                                    color: '#0be5ff'
                                }
                            }
                        },
                        labelLine: {
                            length: 12,
                            length2: 25,
                            maxSurfaceAngle: 80
                        },
                        data: this.pieValue
                    }
                ]
            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
    },
    // 生命周期，创建完成时（可以访问当前this实例）
    created() {

    },
    // 生命周期：挂载完成时（可以访问DOM元素）
    mounted() {
        this.pie_graphics()
    },
}
</script>
<style scoped>
.pie {
    width: 100%;
    height: 100%;
}
</style>